<template>
  <div>
    <!-- <SEARCH :list="searchArr"></SEARCH> -->
    <TABS :tabChange='onCallbackTab' :list="tabsArr"></TABS>
    <TABLE :selectChange='onCallbackSelect' :buttonChange='onCallbackButton' :list="tableArr" :data="dataArr"></TABLE>
    <PAGE :pageChange='onCallbackPage' :limitChange='onCallbackLimit' :count="count"></PAGE>
                <!-- <SEARCH :searchChange='onCallbackSearch' :list="searchArr"></SEARCH>
    <TABS :tabChange='onCallbackTab' :list="tabsArr"></TABS>
    <TABLE :selectChange='onCallbackSelect' :buttonChange='onCallbackButton' :list="tableArr" :data="dataArr"></TABLE>
    <PAGE :pageChange='onCallbackPage' :limitChange='onCallbackLimit' :count="count"></PAGE> -->
  </div>
</template>

<script>
// import SEARCH from '@/components/search' // 顶部搜索
import TABS from '@/components/tabs' // 横向 button
import TABLE from '@/components/table' // 列表
import PAGE from '@/components/page' // 分页
import { getDuty, delDuty } from '@/api/project'
export default {
  name: 'Duty',
  components: {
    // SEARCH,
    TABS,
    TABLE,
    PAGE,
  },
  data() {
    return {
      searchArr: [
        {
          name: '深基坑名称',
          type: 'input',
          options: [],
          key: 'deepName',
        },
        {
          name: '施工进度',
          type: 'picker',
          options: [],
          key: 'conProgress',
        },
        {
          name: '所属工程',
          type: 'picker',
          options: [],
          key: 'fenceProject',
        },
      ], // 搜索列表
      tabsArr: [
        {
          type: 'primary',
          name: '导出数据',
          func: '',
          isLoading: false,
        },
        {
          type: 'danger',
          name: '批量删除',
          func: 'removeDuty',
          isLoading: false,
        },
      ], // 标签列表
      tableArr: [
        {
          prop: 'project.name',
          label: '所属工程',
        },
        {
          prop: 'user.nickname',
          label: '姓名',
        },
        {
          prop: 'user.position_id',
          label: '职务',
        },
        {
          prop: 'unit.unit',
          label: '单位',
        },
        {
          prop: 'unit.group',
          label: '分组',
        },
        {
          prop: 'project.location',
          label: '工程位置',
        },
        {
          prop: 'arrive_address',
          label: '签到位置',
        },
        {
          prop: 'distance',
          label: '距离工程(m)',
        },
        {
          prop: 'status',
          label: '到岗状态',
        },
        {
          prop: 'arrive_time',
          label: '到岗时间',
        },
        {
          prop: '',
          label: '照片',
          type: 'img',
        },
        {
          prop: '',
          label: '操作',
          width: '',
          type: 'but',
        },
      ], // 表头列表
      dataArr: [], // 数据列表
      butnArr: [
        {
          type: '',
          name: '查看坐标',
          func: '',
        },
        {
          type: 'danger',
          name: '删除',
          func: 'removeDuty',
        },
      ], // 数据-操作列表
      count: 0, // 总页数
      formData: {
        page: 1,
        limit: 10,
        type: '',
        name: '',
        unit_id: '',
        create_at: '',
      },
      ids: '',
    }
  },
  created() {},
  mounted() {
    this._loadData()
  },
  methods: {
    _loadData: function () {
      this.formData.unit_id = this.$store.getters.USER_ID
      getDuty(this.formData).then((res) => {
        const { result } = res

        this.count = result.count
        result.data.forEach(item => {
          item.buttons = this.butnArr
          item.status = item.status === 1 ? '正常' : '超出范围'
          // item.imgs.push({ url: 'https://ss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=3564574551,3491080156&fm=26&gp=0.jpg' })
        })
        this.dataArr = result.data
      })
    },

    // 删除到岗到位
    removeDuty: function(key, index) {
      if (!this.ids) {
        this.$toast('请选择要删除的记录')
        this[key][index].isLoading = false
        return false
      }
      delDuty(this.ids).then(res => {
        this.$toast(res.message)
        this._loadData()
        key && index && (this[key][index].isLoading = false)
      })
    },

    // 收到 tab 回调
    onCallbackTab: function({ item, index }) {
      this.tabsArr[index].isLoading = true
      this[item.func]('tabsArr', index)
    },
    // 收到 选择 回调
    onCallbackSelect: function(ids) {
      this.ids = ids
    },
    // 收到 table内方法 回调
    onCallbackButton: function(item, func) {
      this.ids = item.id
      this[func]()
    },

    // 收到改变页码回调
    onCallbackPage: function(page) {
      this.formData.page = page
      this._loadData()
    },
    // 收到改变一页数量回调
    onCallbackLimit: function(limit) {
      this.formData.limit = limit
      this._loadData()
    },
  },
}
</script>

<style lang="stylus" scoped></style>
